<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="w-full bg-primary">
  <div class="content pt-16 pb-16">
    <app-breadcrumbs [segments]="breadcrumbs"></app-breadcrumbs>

    <div class="flex flex-col gap-4 my-8">
      <div class="flex gap-4 items-center w-full justify-between">
        <h1 class="text-white font-bold text-3xl">Cloud Compute Resources</h1>
        <button class="btn-primary py-2 px-3 flex gap-1 items-center" (click)="clipboardURL($event)">
          Share
          <lucide-icon name="{{clipboardIcon}}" size="16" class="ml-2"></lucide-icon>
        </button>
      </div>
      <div class="text-white text-xl pr-16">
        Explore, search, and evaluate the supported cloud compute resources in the table below. This comprehensive comparison includes diverse attributes such as CPU count, detailed processor information, memory, GPU, storage, network speed and capacity, available operating systems. Use the sidebar to filter the results, or enter your freetext query in the “Search prompt” bar. You can also compare servers by selecting at least two rows using the checkboxes.
      </div>
    </div>

    <div class="flex gap-8">
      <div class="filter_bar bg-primary"
        [ngClass]="{
          'collapsed': isCollapsed
        }">
        <div class="collapser bg-secondary" (click)="toggleCollapse()" tabindex="0"
             data-ph-capture-attribute-sc-event="server listing toggle sidebar">
          <lucide-icon name="chevron-left" class="h-6 w-6 text-emerald-400"></lucide-icon>
        </div>

        <app-search-bar
        [query]="query"
        [filterCategories]="filterCategories"
        [searchParameters]="searchParameters"
        (searchChanged)="searchBarChanged($event)"
        > </app-search-bar>
      </div>
      <div class=" list_holder flex flex-col gap-4"
          [ngClass]="{
            'limited-full': !isCollapsed,
            'w-full': isCollapsed
          }">
        <div class="top_buttons w-full flex justify-end gap-6 items-center" >
          <div>
            <button
              id="column_button"
              data-ph-capture-attribute-sc-event="server listing columns select"
              data-dropdown-toggle="column_options"
              class="dropdown_button"
              type="button">
              <div class="px-4 py-3">
              Columns
              </div>
              <div class="px-4 py-3 w-12 h-full bg-primary justify-center items-center flex rounded-r-lg">
                <lucide-icon name="chevron-down" class="h-6 w-6 text-emerald-400"></lucide-icon>
              </div>
            </button>
          </div>
        </div>
        <div>
          <div class="w-full overflow-x-auto relative" style="padding-left: 1px;">
            <div *ngIf="isLoading" class="absolute top-0 left-0 w-full h-full" style="background-color: rgba(0,0,0,0.2);" >
              <div class="flex justify-center mt-16 opacity-100" role="status">
                <svg aria-hidden="true" class="inline w-12 h-12 text-gray-200 animate-spin fill-emerald-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <table class="items_table" id="servers_table">
              <thead>
                <tr>
                  <th class="checkbox_column">
                    <div class="text-white">
                      <lucide-icon class="h-4 w-4" name="scale" ></lucide-icon>
                    </div>
                  </th>
                  <th *ngFor="let item of tableColumns"
                      [ngClass]="{
                        'cursor-pointer': item.orderField
                      }"
                      (click)="toggleOrdering(item)" >
                      <div class="flex gap-1">
                        {{item.name}}
                        <lucide-icon
                          *ngIf="item.info"
                          name="info"
                          (mouseenter)="showTooltip($event, item.info)"
                          (mouseleave)="hideTooltip()"
                          class="h-4 w-4 text-gray-200">
                        </lucide-icon>
                        <lucide-icon *ngIf="getOrderingIcon(item)" name="{{getOrderingIcon(item)}}" class="h-4 w-4 text-white"></lucide-icon>
                      </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of servers; let i = index" (click)="openServerDetails(item)"
                    data-ph-capture-attribute-sc-event="server listing route to server details"
                    [attr.data-ph-capture-attribute-server-listing-row-vendor]="item.vendor_id"
                    [attr.data-ph-capture-attribute-server-listing-row-index]="i+1"
                    [attr.data-ph-capture-attribute-server-listing-row-page-size]="limit"
                    id="server_table_data_line">

                  <td (click)="toggleCompare2($event, item)" class="checkbox_column">
                    <div class="flex items-center">
                      <input type="checkbox"
                        id="server_compare_checkbox_{{item.server_id}}"
                        [(ngModel)]="item.selected"
                        (ngModelChange)="toggleCompare($event, item)"
                        (click)="$event.stopPropagation()"
                        class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none cursor-pointer" >
                    </div>
                  </td>
                  <ng-container *ngFor="let column of tableColumns">
                    <td *ngIf="column.type === 'name'">
                      <div (click)="$event.stopPropagation()">
                        <a routerLink="/server/{{item.vendor.vendor_id}}/{{item.api_reference}}"
                           data-ph-capture-attribute-sc-event="server listing route to server details"
                           [attr.data-ph-capture-attribute-server-listing-row-vendor]="item.vendor_id"
                           [attr.data-ph-capture-attribute-server-listing-row-index]="i+1"
                           [attr.data-ph-capture-attribute-server-listing-row-page-size]="limit">
                          <div class="text-sm text-white flex gap-1">
                            <span>{{item.display_name}} </span>
                            <span *ngIf="item.display_name !== item.api_reference" class="text-emerald-400" >|</span>
                            <span *ngIf="item.display_name !== item.api_reference">{{item.api_reference}} </span>
                          </div>
                          <div class="text-xs text-white flex gap-1">
                            <span>{{item.vendor_id}}</span>
                          </div>
                        </a>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'processor'">
                      <div class="text-sm text-white">{{item.vcpus}} vCPUs ({{item.cpu_architecture}})</div>
                      <div class="flex gap-1">
                        <div
                          *ngIf="item.cpu_cores && item.cpu_speed"
                          class="text-xs text-white flex gap-1">
                          <span>{{item.cpu_cores}} cores &#64; {{item.cpu_speed}} GHz</span>
                        </div>
                        <div
                          *ngIf="item.cpu_allocation && item.cpu_allocation !== 'Dedicated'"
                          class="text-xs text-white flex gap-1">
                          <span>({{item.cpu_allocation}})</span>
                        </div>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'price'">
                      <div class="text-sm text-white">{{item.price}} USD</div>
                    </td>
                    <td *ngIf="column.type === 'score'">
                      <div class="text-sm text-white">{{getScore(item.score)}}</div>
                      <div
                        *ngIf="item.score_per_price"
                        class="text-xs text-white flex gap-1">
                        <span>{{getScore(item.score_per_price)}} / USD</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'memory'">
                      <div class="text-sm text-white">{{getMemory(item)}}</div>
                      <div
                        *ngIf="item.memory_speed"
                        class="text-xs text-white flex gap-1">
                        <span *ngIf="!item.memory_generation">{{item.memory_speed}} MHz</span>
                        <span *ngIf="item.memory_generation">{{item.memory_speed}} MHz ({{item.memory_generation}})</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'gpu_memory_min'">
                      {{getGPUMemory(item, 'min')}}
                    </td>
                    <td *ngIf="column.type === 'gpu_memory_total'">
                      {{getGPUMemory(item, 'total')}}
                    </td>
                    <td *ngIf="column.type === 'storage'">
                      <div class="text-sm text-white">{{getStorage(item)}}</div>
                      <div
                        *ngIf="item.storage_type"
                        class="text-xs text-white">
                        <span>{{item.storage_type}}</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'gpu'">
                      <div class="text-sm text-white">{{item.gpu_count}}</div>
                      <div
                        *ngIf="item.gpu_model"
                        class="text-xs text-white flex gap-1">
                        <span>{{item.gpu_model}}</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'text' && column.key">
                      {{getField(item, column.key)}}
                    </td>
                    <td *ngIf="column.type === 'vendor'" >
                      <div *ngIf="item.vendor.logo" class="w-9 h-9 px-1 py-1 bg-white rounded-lg">
                        <img [src]="item.vendor.logo" alt="{{item.vendor.name}} logo" >
                      </div>
                      <div *ngIf="!item.vendor.logo" class="text-white text-sm">
                          {{item.vendor.name}}
                      </div>
                    </td>
                  </ng-container>
                  <td>
                    <!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
                    <div (click)="$event.stopPropagation()">
                      <a routerLink="/server/{{item.vendor.vendor_id}}/{{item.api_reference}}"
                         data-ph-capture-attribute-sc-event="server listing route to server details"
                         [attr.data-ph-capture-attribute-server-listing-row-vendor]="item.vendor_id"
                         [attr.data-ph-capture-attribute-server-listing-row-index]="i+1"
                         [attr.data-ph-capture-attribute-server-listing-row-page-size]="limit">
                        <lucide-icon name="chevron-right" class="h-6 w-6 text-emerald-400"></lucide-icon>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="flex justify-between mt-4">
              <div>
                <button
                  id="pagesize_button"
                  data-ph-capture-attribute-sc-event="server listing page size select"
                  class="dropdown_button"
                  type="button">
                  <div class="px-4 py-2">
                  {{limit}}
                  </div>
                  <div class="px-2 py-2 w-12 h-full bg-primary justify-center items-center flex rounded-r-lg">
                    <lucide-icon name="chevron-down" class="h-6 w-6 text-emerald-400"></lucide-icon>
                  </div>
                </button>
                <span class="text-white" > items per page</span>
              </div>
              <div>
                <app-pagination
                  baseURL="/servers"
                  [currentPage]="page"
                  [totalPages]="totalPages"
                  [baseQueryParams]="getQueryObjectBase()"
                  >
                </app-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<div *ngIf="compareCount() >= 2" class="fixed_buttons">
  <div class="flex gap-4" >
    <button
      class="flex justify-center bg-red-400 text-white py-2 px-4 rounded-lg font-bold hover:bg-red-500"
      data-ph-capture-attribute-sc-event="server listing compare"
      data-ph-capture-attribute-server-listing-compare-action="open"
      (click)="clearCompare()">
      Clear
    </button>
    <button
      class="flex justify-center bg-emerald-400 text-white py-2 px-4 rounded-lg font-bold hover:bg-emerald-500"
      data-ph-capture-attribute-sc-event="server listing compare"
      data-ph-capture-attribute-server-listing-compare-action="open"
      (click)="openCompare()">
      Compare ({{compareCount()}})
    </button>
  </div>
</div>



<div id="pagesize_options" class="z-10 hidden bg-secondary rounded-lg shadow w-44 border border-emerald-400 border-solid">
  <ul class="py-2 px-1 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
    <li *ngFor="let item of pageLimits"
      class="flex py-1 px-2 gap-2 items-center cursor-pointer hover:bg-emerald-500 rounded-sm"
      (click)="selectPageSize(item)" tabindex="0">
      <div class="text-white text-sm">{{item}} </div>
    </li>
  </ul>
</div>

<div id="column_options" class="z-10 hidden bg-secondary rounded-lg shadow w-52 border border-emerald-400 border-solid">
  <ul class="py-2 px-3 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
    <li *ngFor="let item of possibleColumns" class="flex py-1 gap-2 items-center">
      <input type="checkbox"
      [(ngModel)]="item.show"
      (ngModelChange)="refreshColumns()"
      class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none cursor-pointer" >
      <div class="text-white text-sm">{{item.name}}</div>
    </li>
  </ul>
</div>

<div id="tooltipDefault"
  #tooltipDefault
  style="opacity: 0;"
  class="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-secondary border border-solid border-emerald-400 rounded-lg">
  {{tooltipContent}}
</div>
